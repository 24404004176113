import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../config/constants';
import Loading from '../../components/Loading';
import useAsync from '../../../hooks/useAsync';
import './SumDataStruct.scss';
import { useNavigate } from 'react-router';
import { ConfirmContext } from '../../components/ConfirmModal';
import createIconImg from '../../../images/createIcon.png';
import editDSIcon from '../../../images/editDS.png';
import deleteDSIcon from '../../../images/deleteDS.png';

function ShowSumDataStructure(props) {

    const navigate = useNavigate();
    const { confirm } = useContext(ConfirmContext);
    async function getSumDataStructs() {
        const response = await axios.post(`${API_URL}/monitoring-manage/getSumDataStructs`);
        return response.data;
    }

    const onClickEditBtn = (e, idNo) => {
        e.preventDefault();
        navigate(`/home/dataStructure/sumData/edit/${idNo}`);
    }

    const onClickDeleteBtn = async (e, selectedData) => {
        e.preventDefault();
        const result = await confirm(`${selectedData.name}를(을) 삭제하시겠습니까?`, '삭제', '취소');
        if (result) {
            await axios.post(`${API_URL}/monitoring-manage/deleteSumDataStruct`, { sumDsId: selectedData.sumDsId }).then(res => {
                if (res.data.success) {
                    navigate(0);
                }
            })
        }
    }

    const state = useAsync(getSumDataStructs);
    const { loading, error, data: result } = state;
    if (loading) return <div className='contentArea' style={{ height: '100%' }}><Loading /></div>
    if (error) {
        console.log(error)
        return <div className='contentArea'>페이지를 나타낼 수 없습니다.</div>
    }
    if (!result) return <div>실패</div>;

    console.log(result)
    return (
        <div className='showSumDataStructs'>
            <p className='path'>데이터 구조 관리<span className='below'>{'>'}</span>요약 데이터 구조</p>
            <h2 className='pageTitle'>요약 데이터 구조</h2>

            <div className='tblArea'>
                <ul className='sumDsTbl dataTable'>
                    <li className='thRow'>
                        <ul className='tr'>
                            <li className='name'>데이터 이름</li>
                            <li className='dataNo'>센서 데이터 수</li>
                            <li className='dataName'>센서 데이터 이름</li>
                            <li className='dataClass'>데이터 분류</li>
                            <li className='indexNo'>공정지표 수</li>
                            <li className='indexName'>공정지표 이름</li>
                            <li className='indexType'>데이터 유형</li>
                            <li className='indexDim'>단위</li>
                            <li className='indexStd'>기준값</li>
                            <li className='indexTol1'>오차 상한</li>
                            <li className='indexTol0'>오차 하한</li>
                            <li className='indexComment'>공정지표 코멘트</li>
                            <li className='organization'>소속</li>
                            <li className='userOwner'>담당자</li>
                            <li className='mqttTopic'>MQTT 토픽</li>
                            <li className='jsonKeys'>JSON Key</li>
                            <li className='comment'>코멘트</li>
                            <li className='edit'>수정</li>
                            <li className='delete'>삭제</li>
                        </ul>
                    </li>
                    {
                        result.map((item, index) => {
                            const wholeRowAmount = item?.indexNo.reduce((prev, cur) => prev + cur, 0);
                            return (
                                <li className='tdRow' key={`sumDsId${item.sumDsId}`}>
                                    <ul className='tr'>
                                        <li className='name td' style={{ height: `${wholeRowAmount * 30}px` }}><div className='divInTd'><p>{item.name}</p></div></li>
                                        <li className='dataNo td' style={{ height: `${wholeRowAmount * 30}px` }}><div className='divInTd'><p>{item.dataNo}</p></div></li>
                                        <li className='dataName'>
                                            <ul>
                                                {
                                                    item.dataName.map((name, arrIdx) => {
                                                        const idxAmt = item.indexNo[arrIdx];
                                                        return (
                                                            <li className='dataNameInner td' key={`dataNameInner_${arrIdx}`} style={{ height: `${idxAmt * 30}px` }}>
                                                                <div className='divInTd'><p>{name}</p></div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </li>
                                        <li className='dataClass'>
                                            <ul>
                                                {
                                                    item.dataClass.map((cl, arrIdx) => {
                                                        const idxAmt = item.indexNo[arrIdx];
                                                        return (
                                                            <li className='dataClassInner td' key={`dataClassInner_${arrIdx}`} style={{ height: `${idxAmt * 30}px` }}>
                                                                <div className='divInTd'><p>{cl}</p></div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </li>
                                        <li className='indexNo'>
                                            <ul>
                                                {
                                                    item.indexNo.map((indexNo, arrIdx) => (<li className='indexNoInner td' key={`indexNoInner_${arrIdx}`} style={{ height: `${indexNo * 30}px` }}>
                                                        <div className='divInTd'><p>{indexNo}</p></div>
                                                    </li>))
                                                }
                                            </ul>
                                        </li>
                                        <li className='indexName'>{
                                            item.indexName.map((secondArr, arrIdx) => (<ul key={`indexNameUl_${arrIdx}`}>
                                                {
                                                    secondArr.map((indexName, innerIdx) => (<li className='indexNameInner td' key={`indexNameInner_${innerIdx}`}><div className='divInTd'><p>{indexName}</p></div></li>)
                                                    )
                                                }
                                            </ul>))
                                        }</li>
                                        <li className='indexType'>{
                                            item.indexType.map((secondArr, arrIdx) => (<ul key={`indexTypeUl_${arrIdx}`}>
                                                {
                                                    secondArr.map((indexType, innerIdx) => (<li className='indexTypeInner td' key={`indexTypeInner_${innerIdx}`}><div className='divInTd'><p>{indexType}</p></div></li>)
                                                    )
                                                }
                                            </ul>))
                                        }</li>
                                        <li className='indexDim'>{
                                            item.indexDim.map((secondArr, arrIdx) => (<ul key={`indexDimUl_${arrIdx}`}>
                                                {
                                                    secondArr.map((indexDim, innerIdx) => (<li className='indexDimInner td' key={`indexDimInner_${innerIdx}`}><div className='divInTd'><p>{indexDim ?? '-'}</p></div></li>)
                                                    )
                                                }
                                            </ul>))
                                        }</li>
                                        <li className='indexStd'>{
                                            item.indexStd.map((secondArr, arrIdx) => (<ul key={`indexStdUl_${arrIdx}`}>
                                                {
                                                    secondArr.map((indexStd, innerIdx) => (<li className='indexStdInner td' key={`indexStdInner_${innerIdx}`}><div className='divInTd'><p>{indexStd ?? '-'}</p></div></li>)
                                                    )
                                                }
                                            </ul>))
                                        }</li>
                                        <li className='indexTol1'>{
                                            item.indexTol1.map((secondArr, arrIdx) => (<ul key={`indexTol1Ul_${arrIdx}`}>
                                                {
                                                    secondArr.map((indexTol1, innerIdx) => (<li className='indexTol1Inner td' key={`indexTol1Inner_${innerIdx}`}><div className='divInTd'><p>{indexTol1 ?? '-'}</p></div></li>)
                                                    )
                                                }
                                            </ul>))
                                        }</li>
                                        <li className='indexTol0'>{
                                            item.indexTol0.map((secondArr, arrIdx) => (<ul key={`indexTol0Ul_${arrIdx}`}>
                                                {
                                                    secondArr.map((indexTol0, innerIdx) => (<li className='indexTol0Inner td' key={`indexTol0Inner_${innerIdx}`}><div className='divInTd'><p>{indexTol0 ?? '-'}</p></div></li>)
                                                    )
                                                }
                                            </ul>))
                                        }</li>
                                        <li className='indexComment'>{
                                            item.indexComment.map((secondArr, arrIdx) => (<ul key={`indexCommentUl_${arrIdx}`}>
                                                {
                                                    secondArr.map((indexComment, innerIdx) => (<li className='indexCommentInner td' key={`indexCommentInner_${innerIdx}`}><div className='divInTd'><p>{indexComment ?? '-'}</p></div></li>)
                                                    )
                                                }
                                            </ul>))
                                        }</li>
                                        <li className='organization td' style={{ height: `${wholeRowAmount * 30}px` }}><div className='divInTd'><p>{item["Organization.name"]}</p></div></li>
                                        <li className='userOwner td' style={{ height: `${wholeRowAmount * 30}px` }}><div className='divInTd'>
                                            <p >
                                                {
                                                    item.userOwner.map((el, idx, arr) => {
                                                        if (idx < arr.length - 1) {
                                                            return (
                                                                <React.Fragment key={`indexUserOwner_cont_${idx}`}>
                                                                    <span key={`indexUserOwner_${idx}`}>{el},</span>
                                                                    <br key={`indexUserOwner_br_${idx}`} />
                                                                </React.Fragment>
                                                            )
                                                        } else {
                                                            return (
                                                                <span key={`indexUserOwner_${idx}`}>{el}</span>
                                                            )
                                                        }
                                                    })
                                                }
                                            </p>
                                        </div></li>
                                        <li className='mqttTopic td' style={{ height: `${wholeRowAmount * 30}px` }}><div className='divInTd'><p>{item.mqttTopic}</p></div></li>
                                        <li className='jsonKeys'>{
                                            item.jsonKeys.map((secondArr, arrIdx) => (<ul key={`jsonKeysUl_${arrIdx}`}>
                                                {
                                                    secondArr["data"].map((jsonKeys, innerIdx) => (<li className='jsonKeysInner td' key={`jsonKeysInner_${innerIdx}`}><div className='divInTd'><p>{jsonKeys}</p></div></li>)
                                                    )
                                                }
                                            </ul>))
                                        }</li>
                                        <li className='comment td' style={{ height: `${wholeRowAmount * 30}px` }}><div className='divInTd'>{item.comment ?? '-'}</div></li>
                                        <li className='edit td' style={{ height: `${wholeRowAmount * 30}px` }}>
                                            <div className='divInTd'>
                                                <button className='editBtn' onClick={e => onClickEditBtn(e, item.sumDsId)}>
                                                    <img src={editDSIcon} alt="수정" />
                                                </button>
                                            </div>
                                        </li>
                                        <li className='delete td' style={{ height: `${wholeRowAmount * 30}px` }}>
                                            <div className='divInTd'>
                                                <button className='deleteBtn' onClick={e => onClickDeleteBtn(e, item)}>
                                                    <img src={deleteDSIcon} alt="삭제" />
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='btnsArea'>
                <button className='createBtn' onClick={e => {
                    e.preventDefault();
                    navigate('/home/dataStructure/sumData/create')
                }} >추가<img src={createIconImg} alt={"추가"} /></button>
            </div>
        </div>
    );
}

export default ShowSumDataStructure;