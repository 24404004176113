import React, { useEffect, useRef, useState } from 'react';
import chkMark from '../../images/chk_white.png';
import arrowIconImg from '../../images/filledArrowIcon.png';
import './ChkBoxDropdown.scss';

function ChkBoxDropdown({ value, options, onChange }) {
    const menuRef = useRef();

    // 드롭다운이 열리고 닫히는 상태관리
    const [isOpen, setIsOpen] = useState(false);

    // 목록에 체크가 활성화된 체크박스 상태관리
    const [chkedOption, setChkedOption] = useState([]);

    // 드롭다운 열기 버튼의 onClick 이벤트
    const onClick = e => {
        e.preventDefault();
        if (!isOpen) {
            setIsOpen(true);
        }
    }

    // 체크박스의 onClick 이벤트
    const onCheck = (e, item) => {
        e.preventDefault();
        if (chkedOption.includes(item)) {
            // 이미 체크된 체크박스를 클릭했을 경우

            const copiedArr = [...chkedOption];
            const filteredArr = copiedArr.filter(el => el !== item);
            setChkedOption([...filteredArr]);
        } else {
            // 체크하지 않았던 체크박스를 클릭했을 경우

            setChkedOption([...chkedOption, item]);
        }
    };

    // 적용 버튼의 onClick 이벤트
    const onSubmit = e => {
        e.preventDefault();

        onChange(e, chkedOption);

        // 체크박스 드롭다운 초기화
        setIsOpen(false);
        setChkedOption([]);
    }

    // 체크박스 드롭다운 오픈 시, 기본 체크 값을 추가
    useEffect(() => {
        setChkedOption([...value])
    }, [isOpen, value]);

    // 드롭다운이 열렸을 때, 드롭다운 외 영역을 클릭하면 드롭다운을 닫기
    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
                setChkedOption([...value]);
            }
        }

        // 바깥 클릭 이벤트 리스너 추가
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef])

    // options로 드롭다운에 표기된 체크박스 목록 만들기
    const nameList = value?.map(el => {
        const name = options.find(element => element.id === el)?.name;
        return name;
    });
    return (
        <div className='dataDropDown'>
            <button className={isOpen ? 'dropDownBtn opened' : 'dropDownBtn closed'} onClick={onClick}>
                <p>{nameList?.join(', ')}</p>
                <img src={arrowIconImg} alt='드롭다운' />
                {
                    nameList.length > 0 ?
                        <span className='hint'>{nameList?.join(', ')}</span> : null
                }
            </button>
            <div className={isOpen ? 'dropDownMenu opened' : 'dropDownMenu closed'} ref={menuRef}>
                <ul className='chkBoxUl'>
                    {
                        options.map((option, idx) => {
                            return (
                                <li key={idx} className={chkedOption.includes(option.id) ? 'chkBoxLi chked' : 'chkBoxLi'}>
                                    <button className='chkBox' onClick={e => onCheck(e, option.id)}>
                                        <div className='customChkBox'>
                                            {
                                                chkedOption.includes(option.id) ?
                                                    <img src={chkMark} alt='체크' /> : null
                                            }
                                        </div>
                                        <span>{option.name}</span>
                                    </button>
                                </li>
                            );
                        })
                    }
                </ul>
                <div className='submitBtnArea'>
                    <button className='submitBtn' onClick={onSubmit}>적용</button>
                </div>
            </div>
        </div>
    );
}

export default ChkBoxDropdown;