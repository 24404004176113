import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { API_URL } from '../../../config/constants';
import axios from 'axios';
import IndexLvTds from './components/IndexLvTds';
import DataLvTds from './components/DataLvTds';
import './EditSumDataStruct.scss';
import ChkBoxDropdown from '../../components/ChkBoxDropdown';
import IndexLvTdsWithInfo from './components/IndexLvTdsWithInfo';

function EditSumDataStruct(props) {
    async function getSumDataStruct() {
        axios.post(`${API_URL}/monitoring-manage/getSumDataStruct`, { sumDsId: param.id }).then(res => {
            const { data, users } = res.data;
            setOrigData({ ...data });
            setFormData({
                ...data
            });
            setUsers([...users]);
        }).catch(err => console.error(err));
    }



    const param = useParams();
    const navigate = useNavigate();
    const defaultDataForm = {
        name: '',
        dataNo: 1,
        dataName: [''],
        dataClass: [''],
        indexNo: [1],
        indexName: [['']],
        indexType: [['']],
        indexDim: [[null]],
        indexStd: [[null]],
        indexTol1: [[null]],
        indexTol0: [[null]],
        indexComment: [[null]],
        organization: '',
        userOwner: [],
        mqttTopic: '',
        jsonKeys: [{ "data": [''] }],
        columnName: [{ "data": [''] }],
        comment: null,
    };
    const [origData, setOrigData] = useState(defaultDataForm)
    const [formData, setFormData] = useState(defaultDataForm);
    const [users, setUsers] = useState([]);
    const [changed, setChanged] = useState(false);


    const chgMonit = () => {
        const changed = JSON.stringify(formData) !== JSON.stringify(origData);
        setChanged(changed);
    };
    console.log(formData)
    const wholeColAmount = formData.indexNo.reduce((prev, cur) => prev + cur, 0);
    const onChange = (e) => {
        e.preventDefault();
        const { name, value, type } = e.target;
        let convVal = type === 'number' ? parseInt(value) : value;
        if (name === 'dataNo') {
            // 현재 센서 데이터 수
            const curDataAmount = formData.dataNo;
            const { dataName, dataClass, indexNo, indexName, indexType, indexDim, indexStd, indexTol1, indexTol0, indexComment, jsonKeys, columnName } = formData;
            const increaseFx = (curArr, incr, defaultValue) => ([...curArr, ...Array(incr).keys().map((_) => (defaultValue))]);
            const decreaseFx = (curArr, newAmount) => (curArr.slice(0, newAmount));
            if (convVal > curDataAmount) {
                // 센서 데이터 수를 증가시킨 경우
                const increase = convVal - curDataAmount;
                const newDataName = increaseFx(dataName, increase, '');
                const newDataClass = increaseFx(dataClass, increase, '');
                const newIndexNo = increaseFx(indexNo, increase, 1);
                const newIndexName = increaseFx(indexName, increase, ['']);
                const newIndexType = increaseFx(indexType, increase, ['']);
                const newIndexDim = increaseFx(indexDim, increase, [null]);
                const newIndexStd = increaseFx(indexStd, increase, [null]);
                const newIndexTol1 = increaseFx(indexTol1, increase, [null]);
                const newIndexTol0 = increaseFx(indexTol0, increase, [null]);
                const newIndexComment = increaseFx(indexComment, increase, [null]);
                const newJsonKeys = increaseFx(jsonKeys, increase, { data: [''], info: [null, ''] });
                const newColumnName = increaseFx(columnName, increase, { data: [''], info: ['', ''] });

                setFormData(prev => ({
                    ...prev,
                    dataNo: convVal,
                    dataName: newDataName,
                    dataClass: newDataClass,
                    indexNo: newIndexNo,
                    indexName: newIndexName,
                    indexType: newIndexType,
                    indexDim: newIndexDim,
                    indexStd: newIndexStd,
                    indexTol1: newIndexTol1,
                    indexTol0: newIndexTol0,
                    indexComment: newIndexComment,
                    jsonKeys: newJsonKeys,
                    columnName: newColumnName,
                }));

            } else {
                // 센서 데이터 수를 감소시킨 경우
                const newDataName = decreaseFx(dataName, convVal);
                const newDataClass = decreaseFx(dataClass, convVal);
                const newIndexNo = decreaseFx(indexNo, convVal);
                const newIndexName = decreaseFx(indexName, convVal);
                const newIndexType = decreaseFx(indexType, convVal);
                const newIndexDim = decreaseFx(indexDim, convVal);
                const newIndexStd = decreaseFx(indexStd, convVal);
                const newIndexTol1 = decreaseFx(indexTol1, convVal);
                const newIndexTol0 = decreaseFx(indexTol0, convVal);
                const newIndexComment = decreaseFx(indexComment, convVal);
                const newJsonKeys = decreaseFx(jsonKeys, convVal);
                const newColumnName = decreaseFx(columnName, convVal);

                setFormData(prev => ({
                    ...prev,
                    dataNo: convVal,
                    dataName: newDataName,
                    dataClass: newDataClass,
                    indexNo: newIndexNo,
                    indexName: newIndexName,
                    indexType: newIndexType,
                    indexDim: newIndexDim,
                    indexStd: newIndexStd,
                    indexTol1: newIndexTol1,
                    indexTol0: newIndexTol0,
                    indexComment: newIndexComment,
                    jsonKeys: newJsonKeys,
                    columnName: newColumnName,
                }))
            }
        } else {
            setFormData(prev => ({ ...prev, [name]: convVal }));
        }
    };

    const onChangeDataLevel = (e, tgtName, index) => {
        e.preventDefault();
        const { value, type } = e.target;
        const convVal = type === 'number' ? parseInt(value) : value;
        const newArray = [...formData[tgtName]];
        newArray[index] = convVal;
        if (tgtName === 'indexNo') {
            // 현재 센서 데이터 수
            const curIndexNo = formData.indexNo[index];
            const { indexName, indexType, indexDim, indexStd, indexTol1, indexTol0, indexComment, jsonKeys, columnName } = formData;
            const increaseFx = (curArr, incr, defaultValue) => {
                let copiedArr = [...curArr];
                copiedArr[index] = [...copiedArr[index], ...Array(incr).keys().map((_) => (defaultValue))];
                return copiedArr;
            };
            const increaseJKFx = (curArr, incr, defaultValue) => {
                let copiedArr = [...curArr];
                copiedArr[index]["data"] = [...copiedArr[index]["data"], ...Array(incr).keys().map((_) => (defaultValue))];
                return copiedArr;
            };
            const decreaseFx = (curArr, newAmount) => {
                const copiedArr = [...curArr];
                copiedArr[index] = copiedArr[index].slice(0, newAmount);
                return copiedArr;
            };
            const decreaseJKFx = (curArr, newAmount) => {
                const copiedArr = [...curArr];
                copiedArr[index]["data"] = copiedArr[index]["data"].slice(0, newAmount);
                return copiedArr;
            };
            if (convVal > curIndexNo) {
                // 센서 데이터 수를 증가시킨 경우
                const increase = convVal - curIndexNo;
                const newIndexName = increaseFx(indexName, increase, '');
                const newIndexType = increaseFx(indexType, increase, '');
                const newIndexDim = increaseFx(indexDim, increase, null);
                const newIndexStd = increaseFx(indexStd, increase, null);
                const newIndexTol1 = increaseFx(indexTol1, increase, null);
                const newIndexTol0 = increaseFx(indexTol0, increase, null);
                const newIndexComment = increaseFx(indexComment, increase, null);
                const newJsonKeys = increaseJKFx(jsonKeys, increase, '');
                const newColumnName = increaseJKFx(columnName, increase, '');
                console.log(newIndexTol0)
                setFormData(prev => ({
                    ...prev,
                    [tgtName]: newArray,
                    indexName: newIndexName,
                    indexType: newIndexType,
                    indexDim: newIndexDim,
                    indexStd: newIndexStd,
                    indexTol1: newIndexTol1,
                    indexTol0: newIndexTol0,
                    indexComment: newIndexComment,
                    jsonKeys: newJsonKeys,
                    columnName: newColumnName,
                }));

            } else {
                // 센서 데이터 수를 감소시킨 경우
                const newIndexName = decreaseFx(indexName, convVal);
                const newIndexType = decreaseFx(indexType, convVal);
                const newIndexDim = decreaseFx(indexDim, convVal);
                const newIndexStd = decreaseFx(indexStd, convVal);
                const newIndexTol1 = decreaseFx(indexTol1, convVal);
                const newIndexTol0 = decreaseFx(indexTol0, convVal);
                const newIndexComment = decreaseFx(indexComment, convVal);
                const newJsonKeys = decreaseJKFx(jsonKeys, convVal);
                const newColumnName = decreaseJKFx(columnName, convVal);

                setFormData(prev => ({
                    ...prev,
                    [tgtName]: newArray,
                    indexName: newIndexName,
                    indexType: newIndexType,
                    indexDim: newIndexDim,
                    indexStd: newIndexStd,
                    indexTol1: newIndexTol1,
                    indexTol0: newIndexTol0,
                    indexComment: newIndexComment,
                    jsonKeys: newJsonKeys,
                    columnName: newColumnName,
                }))
            }
        } else {
            setFormData(prev => ({ ...prev, [tgtName]: newArray }));
        }
    };

    const onChangeIndexLevel = (e, tgtName, dataLvIdx, index) => {
        e.preventDefault();
        const { value, type } = e.target;
        const convVal = type === 'number' ? parseFloat(value) : tgtName === 'indexType' ? value.toUpperCase() : value;
        const newOuterArr = [...formData[tgtName]];
        newOuterArr[dataLvIdx][index] = convVal;
        setFormData(prev => ({ ...prev, [tgtName]: newOuterArr }));
    };

    const onChangeIndexLevelWithInfo = (e, tgtName, dataLvIdx, index) => {
        e.preventDefault();
        const { value, type } = e.target;
        const convVal = type === 'number' ? parseFloat(value) : tgtName === 'indexType' ? value.toUpperCase() : value;
        if (tgtName === 'jsonKeys') {
            const newJKArr = [...formData[tgtName]];
            const newCNArr = [...formData['columnName']];
            newJKArr[dataLvIdx]["data"][index] = convVal;
            newCNArr[dataLvIdx]["data"][index] = convVal;
            setFormData(prev => ({ ...prev, [tgtName]: newJKArr, columnName: newCNArr }));
        } else {

            const newOuterArr = [...formData[tgtName]];
            newOuterArr[dataLvIdx]["data"][index] = convVal;
            setFormData(prev => ({ ...prev, [tgtName]: newOuterArr }));
        }
    };
    const onChangeUserOwner = (value) => {
        setFormData(prev => ({ ...prev, userOwner: value }));
    };

    const onSubmit = async e => {
        e.preventDefault();
        console.log(formData)
        const { sumDsId, ...rest } = formData;
        await axios.post(`${API_URL}/monitoring-manage/updateSumDataStruct`, { sumDsId, newData: rest }).then(res => {
            const { success } = res.data;
            if (success) {
                navigate('/home/dataStructure/sumData')
            }
        })

    };

    const onCancel = e => {
        e.preventDefault();
        setFormData(origData);
    };

    useEffect(() => {
        chgMonit();
    }, [formData])
    useEffect(() => {
        getSumDataStruct();
    }, [param.id]);
    return (
        <div className='editSumDataStruct'>
            <p className='path'>데이터 구조 관리<span className='below'>{'>'}</span>요약 데이터 구조<span className='below'>{'>'}</span>요약 데이터 구조 수정</p>
            <h2 className='pageTitle'>요약 데이터 구조 수정</h2>
            <table>
                <tbody>
                    <tr>
                        <th>데이터 이름</th>
                        <td colSpan={wholeColAmount}>
                            <input name='name' value={formData.name ?? ''} onChange={onChange} />
                        </td>
                    </tr>
                    <tr>
                        <th>센서 데이터 수</th>
                        <td colSpan={wholeColAmount}>
                            <input type='number' name='dataNo' value={formData.dataNo ?? 1} onChange={onChange} min={1} />
                        </td>
                    </tr>
                    <tr>
                        <th>센서 데이터 이름</th>
                        <DataLvTds
                            arr={formData.dataName}
                            indexNo={formData.indexNo}
                            title={'dataName'}
                            onChange={onChangeDataLevel}
                        />
                    </tr>
                    <tr>
                        <th>데이터 분류</th>
                        <DataLvTds
                            arr={formData.dataClass}
                            indexNo={formData.indexNo}
                            title={'dataClass'}
                            onChange={onChangeDataLevel}
                        />
                    </tr>
                    <tr>
                        <th>공정지표 수</th>
                        <DataLvTds
                            arr={formData.indexNo}
                            indexNo={formData.indexNo}
                            title={'indexNo'}
                            inputType={'number'}
                            onChange={onChangeDataLevel}
                        />
                    </tr>
                    <tr>
                        <th>공정지표 이름</th>
                        <IndexLvTds
                            outArr={formData.indexName}
                            title={'indexName'}
                            onChange={onChangeIndexLevel}
                        />
                    </tr>
                    <tr>
                        <th>데이터 유형</th>
                        <IndexLvTds
                            outArr={formData.indexType}
                            title={'indexType'}
                            onChange={onChangeIndexLevel}
                            isType={true}
                        />
                    </tr>
                    <tr>
                        <th>단위</th>
                        <IndexLvTds
                            outArr={formData.indexDim}
                            title={'indexDim'}
                            onChange={onChangeIndexLevel}
                        />
                    </tr>
                    <tr>
                        <th>기준값</th>
                        <IndexLvTds
                            outArr={formData.indexStd}
                            title={'indexStd'}
                            inputType={'number'}
                            onChange={onChangeIndexLevel}
                        />
                    </tr>
                    <tr>
                        <th>오차 상한</th>
                        <IndexLvTds
                            outArr={formData.indexTol1}
                            title={'indexTol1'}
                            inputType={'number'}
                            onChange={onChangeIndexLevel}
                        />
                    </tr>
                    <tr>
                        <th>오차 하한</th>
                        <IndexLvTds
                            outArr={formData.indexTol0}
                            title={'indexTol0'}
                            inputType={'number'}
                            onChange={onChangeIndexLevel}
                        />
                    </tr>
                    <tr>
                        <th>공정지표 코멘트</th>
                        <IndexLvTds
                            outArr={formData.indexComment}
                            title={'indexComment'}
                            onChange={onChangeIndexLevel}
                        />
                    </tr>
                    <tr>
                        <th>소속</th>
                        <td colSpan={wholeColAmount}>
                            <input name='organization' value={formData.organization ?? ''} onChange={onChange} />
                        </td>
                    </tr>
                    <tr>
                        <th>담당자</th>
                        <td colSpan={wholeColAmount}>
                            <ChkBoxDropdown
                                value={formData.userOwner}
                                options={users.map(el => ({ id: el.id, name: el.userName }))}
                                onChange={(e, value) => {
                                    e.preventDefault();
                                    onChangeUserOwner(value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>MQTT 토픽</th>
                        <td colSpan={wholeColAmount}>
                            <input name='mqttTopic' value={formData.mqttTopic ?? ''} onChange={onChange} />
                        </td>
                    </tr>
                    <tr>
                        <th>JSON Key</th>
                        <IndexLvTdsWithInfo
                            outArr={formData.jsonKeys}
                            title={'jsonKeys'}
                            onChange={onChangeIndexLevelWithInfo}
                        />
                    </tr>
                    <tr>
                        <th>코멘트</th>
                        <td colSpan={wholeColAmount}>
                            <input name='comment' value={formData.comment ?? ''} onChange={onChange} />
                        </td>
                    </tr>
                </tbody>

            </table>

            <div className='btnsArea'>
                <button className='submitBtn' onClick={onSubmit}>확인</button>
                <button className='cancelBtn' onClick={onCancel}>취소</button>
            </div>

        </div>
    );
}

export default EditSumDataStruct;