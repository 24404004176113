import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import { useNavigate, useOutletContext } from 'react-router';
import Pagination from '../components/Pagination';
import NewMembersModal from './NewMembersModal';
import arrowIconImg from '../../images/filledArrowIcon.png';
import ChkDelModal from '../components/ChkDelModal';
import Select from 'react-select';
import CustomCheckbox from '../components/CustomCheckbox';
import ChkBoxDropdown from '../components/ChkBoxDropdown';

function MembersMain(props) {

    const navigate = useNavigate();
    const { chkAll, setChkAll, chkedData, setChkedData } = useOutletContext();

    const limit = 23;
    const [page, setPage] = useState(1);
    const [modalIsOpen, setIsOpen] = useState(false);
    const offset = (page - 1) * limit;
    const [memberList, setMemberList] = useState([]);
    const [chkDelModalIsOpen, setChkDelModalIsOpen] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [userRanks, setUserRanks] = useState([]);
    const [datas, setDatas] = useState([]);
    const { userRank } = useSelector((state) => state.session);

    const onEditUser = (e) => {
        e.preventDefault();
        navigate(`/home/members/edit/${e.target.value}`);
    };

    const onClickDelBtn = (e) => {
        e.preventDefault();
        setChkDelModalIsOpen(true);
    };

    const onDeleteUser = async (e) => {
        e.preventDefault();
        const chkedDataArr = [...chkedData];
        await axios.post(`${API_URL}/monitoring-manage/deleteUsers`, { id: chkedDataArr }).then(res => {
            window.location.href = '/home/members';
        }).catch(err => console.error(err));
    };

    const onCheckElement = (checked, item) => {
        if (checked) {
            setChkedData([...chkedData, item]);
        } else {
            setChkedData(chkedData.filter(el => el !== item));
        };
    };

    const onCheckAll = (e) => {
        if (!chkAll) {
            setChkAll(true);
            const idsInThisPage = memberList.slice(offset, offset + limit).map(item => item.id.toString());
            setChkedData(idsInThisPage);
        } else {
            setChkAll(false);
            setChkedData([]);
        };
    };

    const onChange = async (sel, targetData) => {
        let chkedDataArr = [...chkedData];
        let changingDatas = [targetData.id.toString(), ...chkedDataArr];
        const set = new Set(changingDatas);
        const uniqueArr = [...set];
        const reqArr = uniqueArr.map(item => ({ id: item, newValue: { userRank: sel.value } }));
        await axios.post(`${API_URL}/monitoring-manage/updateUser`, reqArr).then(res => {
            const copiedArr = [...memberList];
            const filteredArr = copiedArr.filter(item => !uniqueArr.includes(item.id.toString()));
            setMemberList([...filteredArr, ...res.data].sort((a, b) => a.id - b.id));
            setChkAll(false);
            setChkedData([]);
        });
    };

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        setChkDelModalIsOpen(false);
    }

    async function getUserData() {
        await axios.post(`${API_URL}/monitoring-manage/users`, {
            pageNo: page,
        }).then(res => {
            const { users, rowCount, userRanks, datas } = res.data
            setUserRanks([...userRanks]);
            setMemberList([...users]);
            setTotalRowCount(rowCount);
            setDatas([...datas]);
            console.log('res.data', res.data);
        })
    };

    const onChangeDatas = async (id, value) => {
        const reqArr = [{ id: id, newValue: { data: value } }];
        let changingDatas = [id.toString()];
        const set = new Set(changingDatas);
        const uniqueArr = [...set];
        await axios.post(`${API_URL}/monitoring-manage/updateUser`, reqArr).then(res => {
            const copiedArr = [...memberList];
            const filteredArr = copiedArr.filter(item => !uniqueArr.includes(item.id.toString()));
            setMemberList([...filteredArr, ...res.data].sort((a, b) => a.id - b.id));
            setChkAll(false);
            setChkedData([]);
        });
    }

    useEffect(() => {
        getUserData();
    }, [page]);

    useEffect(() => {
        setChkedData([]);
    }, [page, setChkedData])

    // const optionArr = userRanks.map(item => ({ label: item.rank, value: item.rank }));
    const optionArr = userRanks
    .filter(item => item.rank >= userRank)
    .map(item => ({ label: item.rank, value: item.rank }));

    return (
        <React.Fragment>
            <div className='memberMainArea'>
                <p className='path'>회원 관리</p>
                <h2 className='pageTitle'>회원 관리</h2>
                <div className='pageContent'>
                    <div className='btnsArea'>
                        <button className='newUser' onClick={openModal}>신규회원보기</button>
                        <button className='deleteUser' onClick={onClickDelBtn} disabled={chkedData.length === 0}>회원삭제</button>
                    </div>
                    <table className='membersTable'>
                        <thead>
                            <tr>
                                <th className='userChkbox'>
                                    <CustomCheckbox
                                        id={`checkbox_all`}
                                        checked={chkAll}
                                        onChange={onCheckAll}
                                        value={"all"}
                                    />
                                    {/* <input type="checkbox" checked={chkAll} onChange={onCheckAll} */}
                                    {/* value={"all"} /> */}
                                </th>
                                <th className='userName'>이름</th>
                                <th className='userEmail'>이메일</th>
                                <th className='userCompany'>소속</th>
                                <th className='userRank'>레벨</th>
                                <th className='data'>관리 데이터</th>
                                <th className='editBtnTh'>수정</th>
                            </tr>
                            <tr style={{ height: 0, }} />
                        </thead>
                        <tbody>
                            {memberList .filter(item => item.userRank >= userRank).slice(offset, offset + limit).map((item, index, arr) => {
                                return (
                                    <tr key={index}>
                                        <td className='userChkbox'>
                                            <CustomCheckbox
                                                id={`checkbox_${item.id}`}
                                                checked={chkedData.includes(`${item.id}`)}
                                                onChange={e => { onCheckElement(e.target.checked, e.target.value); }}
                                                value={item.id}
                                            />
                                            {/* <input type="checkbox" checked={chkedData.includes(`${item.id}`)} onChange={e => { onCheckElement(e.target.checked, e.target.value); }} value={item.id} /> */}
                                        </td>
                                        <td className='userName'>{item.userName}</td>
                                        <td className='userEmail'>{item.userEmail}</td>
                                        <td className='userCompany'>{item.Organization.name}</td>
                                        <td className='userRank'>
                                            <div className='innerDiv'>
                                                <Select
                                                    options={optionArr}
                                                    onChange={sel => onChange(sel, item)}
                                                    value={optionArr.find(el => el.value === item.userRank)}
                                                    isSearchable={false}
                                                    maxMenuHeight={'225px'}
                                                    classNames={{
                                                        control: (state) => 'control',
                                                        container: (state) => 'selectBox',
                                                        dropdownIndicator: (state) => 'arrow',
                                                        menu: (state) => 'options',
                                                        option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                        singleValue: (state) => 'singleVal',
                                                        valueContainer: (state) => 'valueContainer',
                                                    }}
                                                    components={{
                                                        DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                        IndicatorSeparator: (state) => null,
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className='data'>{
                                            <div className='innerDiv'>
                                                <ChkBoxDropdown
                                                    value={item.data}
                                                    options={datas.map(el => ({ id: el.sumDsId, name: el.name }))}
                                                    onChange={(e, value) => {
                                                        e.preventDefault();
                                                        onChangeDatas(item.id, value)
                                                    }}
                                                />
                                            </div>
                                        }</td>
                                        <td className='userEdit'>
                                            <button value={item.id} onClick={onEditUser}>선택</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Pagination
                        total={totalRowCount}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            </div>
            <NewMembersModal
                data={memberList.filter(item => item.userRank === 9)}
                userRanks={userRanks}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel={"신규 회원 확인 팝업"}
                memberList={memberList}
                setMemberList={setMemberList}
            />
            <ChkDelModal
                isOpen={chkDelModalIsOpen}
                onRequestClose={closeModal}
                onSubmit={onDeleteUser}
            />

        </React.Fragment>
    );
}

export default MembersMain;