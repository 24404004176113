import React, { useState } from 'react';
import './Pagination.scss';

function Pagination({ total, limit, page, setPage }) {
    const numPages = Math.ceil(total / limit);
    const paginationLimit = 7;
    const [curPage, setCurPage] = useState(page);
    const firstNum = curPage - (curPage % paginationLimit) + 1;
    const lastNum = curPage - (curPage % paginationLimit) + paginationLimit;
    const [inputNo, setInputNo] = useState(page);
    const onChangeInput = e => {
        e.preventDefault();
        const value = parseInt(e.target.value);
        if (value < 1) {
            setInputNo(1);
        } else if (value > numPages) {
            setInputNo(numPages)
        } else {
            setInputNo(value);
        }
    };
    return (
        <nav className='paginationNav'>
            <button onClick={() => { setPage(page - 1); setCurPage(page - 2); }} disabled={page === 1}>&lt;</button>
            <button onClick={() => setPage(firstNum)} aria-current={page === firstNum ? "page" : null}>{firstNum}</button>
            {Array(paginationLimit - 1).fill().map((_, i) => {
                if (numPages >= paginationLimit && i <= paginationLimit - 3) {
                    return (
                        <button
                            border="true"
                            key={i + 1}
                            onClick={() => { setPage(firstNum + 1 + i) }}
                            aria-current={page === firstNum + 1 + i ? "page" : null}>
                            {firstNum + 1 + i}
                        </button>
                    )
                }
                else if (numPages >= paginationLimit && i >= paginationLimit - 2) {
                    return (
                        <button
                            border="true"
                            key={i + 1}
                            onClick={() => setPage(lastNum)}
                            aria-current={page === lastNum ? "page" : null}>
                            {lastNum}
                        </button>
                    )
                } else {
                    return null;
                }
            })}
            <button onClick={() => { setPage(page + 1); setCurPage(page); }} disabled={page === numPages}>&gt;</button>
            <div className='goToBtnsArea'>
                <div className='inputArea'>
                    <input
                        type="number"
                        value={inputNo}
                        style={{ textAlign: 'center' }}
                        onChange={onChangeInput}
                        min={1}
                        max={numPages}
                    ></input>
                    <span>/</span>
                    <p>{numPages}</p>
                </div>
                <button
                    style={{ backgroundColor: '#0A2B83', borderRadius: '5px', padding: '4px 8px', color: 'white' }}
                    onClick={() => {
                        setPage(inputNo);
                        setCurPage(inputNo - 1);
                    }}
                >GO</button>
            </div>
        </nav>
    );
}

export default Pagination;