import { Scrollbars } from 'rc-scrollbars';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MoonLoader } from 'react-spinners';

function MonitTable({ dataStructures, selSensorIdx, selDataIdx, formData, onChangeInput, userIsNormal, isEditMode, loading, setLoading }) {
    const [fullDatas, setFullDatas] = useState([]);
    const [loadedData, setLoadedData] = useState([]);
    const [stopGetMore, setStopGetMore] = useState(false);
    const limit = 50;
    const [page, setPage] = useState(1)
    const offset = (page - 1) * limit;
    const data = { ...formData[0] };
    const tableH = 28 * (Object.keys(data).length - 1) + 16;
    const count = Object.keys(data).length - 2;
    const dataNameArr = [...dataStructures[selSensorIdx].rawName];
    const scrollbarRef = useRef();

    const handleObserver = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && loadedData.length >= 50 && !stopGetMore) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const obsRef = useRef();
    useEffect(() => {
        console.log('첫 마운트')
        const observer = new IntersectionObserver(handleObserver, { threshold: 0.5 });
        if (obsRef.current) observer.observe(obsRef.current);
        return () => { observer.disconnect(); }
    }, [handleObserver]);
    useEffect(() => {
        console.log('formData 변경')
        setFullDatas([...formData]);
        const initData = formData.slice(0, limit);
        setLoadedData([...initData])
    }, [formData]);
    useEffect(() => {
        setPage(1);
        scrollbarRef.current?.scrollToLeft();
    }, [selSensorIdx, selDataIdx]);
    useEffect(() => {
        if (page !== 1) {
            console.log(offset, offset + limit)
            const nextData = fullDatas.slice(offset, offset + limit);
            setLoadedData(prev => [...prev, ...nextData]);
            setStopGetMore(true);
            setTimeout(() => {
                setStopGetMore(false);
            }, 200);
        }
    }, [page]);

    return (
        // loading ? <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        //     <MoonLoader color='#0A2B83' size={40} />
        // </div> :
        <div className='monitTbl'>
            <div className='thead'>
                <ul>
                    <li className='row th'><div>{"time"}</div></li>
                    {
                        dataNameArr.map((el, idx) => <li className='row th' key={idx}><div>{el}</div></li>)
                    }
                </ul>
            </div>
            <div className='tbody'>
                <Scrollbars style={{ width: '100%', height: `${tableH}px` }} id='scrollArea' ref={scrollbarRef}>
                    <ul className='tbodyUl'>
                        {
                            loadedData.map((el, index) => {
                                console.log(dataStructures[selSensorIdx].rawType)
                                return (<MemoizedDataCol key={index} el={el} index={index} isEditMode={isEditMode} onChangeInput={onChangeInput} userIsNormal={userIsNormal} rawTypes={dataStructures[selSensorIdx].rawType} />);
                            })
                        }
                        {/* {
                            dataColLoading && <li><MoonLoader color='#0A2B83' size={15} /></li>
                        } */}
                        <li>
                            <div id='observer' style={{ width: "50px", height: "100%" }} ref={obsRef} />
                        </li>
                    </ul>
                </Scrollbars>
            </div>
        </div>
    );
}

function DataCol({ el, index, isEditMode, onChangeInput, userIsNormal, rawTypes }) {
    const { time, id, ...rest } = el;
    console.log(rawTypes)
    return (
        <li className='col' key={id}>
            <div>
                <ul>

                    <li className='row'>
                        <InputComponent el={el} index={index} name={`time`} onChangeInput={onChangeInput} isDisabled={!isEditMode || userIsNormal} />
                    </li>
                    {Object.keys(rest).map((element, idx) => <li className='row' key={idx}>
                        <InputComponent el={el} index={index} name={element} onChangeInput={onChangeInput} isDisabled={!isEditMode || userIsNormal} rawType={rawTypes[idx]} />
                    </li>)}
                </ul>
            </div>
        </li>
    );
}

const MemoizedDataCol = React.memo(DataCol);

function InputComponent({ el, index, name, onChangeInput, isDisabled, rawType }) {
    const [isFocused, setIsFocused] = useState(false);
    const onFocus = e => {
        setIsFocused(true);
    };
    const onBlur = e => {
        setIsFocused(false);
    }
    return (
        <input type='number' name={name} value={rawType === 'TINYINT' ? parseInt(el[name]) : isFocused ? parseFloat(el[name]) : parseFloat(el[name]).toFixed(3)} onFocus={onFocus} onBlur={onBlur} onChange={e => onChangeInput(e, index, name)} disabled={isDisabled} />
    )
}

export default MonitTable;